<template>
    <div class="subscription-card depth-1 padding-medium">
        <div class="left-side">
            <h2 class="left-header">
                {{ $t("subscription_tier_basic") }}
                <span
                    class="pill label flex-center mleft-small"
                    :class="pillColor"
                >
                    {{ pillText }}
                </span>
            </h2>
            <span class="grey trial-period-text">
                <span v-if="subscriptionDaysLeft !== undefined && subscriptionDaysLeft <= 0">
                    {{ $t("subscription_expired") }}
                </span>
                <span v-else>
                    {{ $t("subscription_card_days_left", [subscriptionStatus, subscriptionDaysLeft || 0]) }}
                </span>
            </span>
            <br/>
            <p>
                {{ $t("subscription_card_features_text") }}
            </p>
            <b>
                {{ $t("subscription_card_active_users", [intranet.userCount]) }}
            </b>
        </div>
        <div class="right-side">
            <div class="pricing flex">
                <span class="currency">€</span>
                <span class="number mright-xsmall">1</span>
                <span class="grey period">{{ $t("subscription_card_pricing_period") }}</span>
            </div>
            <everest-button
                :busy="isLoadingStripeSession"
                type="primary"
                @click="onOpenSessionClick"
            >
                {{ hasSubscription ? $t("subscription_manage_button_text") : $t("subscription_upgrade_button_text") }}
            </everest-button>
        </div>
    </div>
</template>

<script>

import EverestButton from "@/components/Button";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import {
    INTRANET,
    SUBSCRIPTION_DAYS_LEFT,
    SUBSCRIPTION_EXPIRATION_DATE,
    SUBSCRIPTION_STATUS,
} from "@/store/intranet/getters";
import { ProductSubscriptionStatus } from "@backend/product-subscription/types";
import { productSubscriptionService } from "@/services";

export default {
    name: "SubscriptionCard",
    components: { EverestButton },
    data() {
        return {
            isLoadingStripeSession: false,
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
            subscriptionStatus: INTRANET_MODULE_NAME + SUBSCRIPTION_STATUS,
            expirationDate: INTRANET_MODULE_NAME + SUBSCRIPTION_EXPIRATION_DATE,
            subscriptionDaysLeft: INTRANET_MODULE_NAME + SUBSCRIPTION_DAYS_LEFT,
        }),
        bodyText() {
            const paymentErrorStates = [
                ProductSubscriptionStatus.incomplete,
                ProductSubscriptionStatus.incompleteExpired,
                ProductSubscriptionStatus.unpaid,
            ];
            if (paymentErrorStates.includes(this.subscriptionStatus)) {
                return this.$t("subscription_card_payment_failed_text");
            }
            return this.$t("subscription_card_features_text");
        },
        pillText() {
            if (this.subscriptionStatus === ProductSubscriptionStatus.trialing) {
                if (this.subscriptionDaysLeft === undefined || this.subscriptionDaysLeft <= 0) {
                    return this.$t("subscription_status_trial_ended");
                } else {
                    return this.$t("subscription_status_trialing");
                }
            }
            if (this.subscriptionStatus === ProductSubscriptionStatus.active) {
                return this.$t("subscription_status_active");
            }
            if (this.subscriptionStatus === ProductSubscriptionStatus.canceled) {
                return this.$t("subscription_status_canceled");
            }

            return this.$t("subscription_status_waiting_for_payment");
        },
        pillColor() {
            if (
                this.subscriptionDaysLeft === undefined ||
                this.subscriptionDaysLeft <= 0 ||
                (this.subscriptionStatus !== ProductSubscriptionStatus.trialing && this.subscriptionStatus !== ProductSubscriptionStatus.active)
            ) {
                return "warning";
            }
            return "secondary";
        },
        hasSubscription() {
            return !!(this.intranet.subscription && this.intranet.subscription.stripeSubscription);
        },
    },
    methods: {
        async openStripeSession(urlProvider) {
            try {
                this.isLoadingStripeSession = true;
                const stripeSession = await urlProvider();
                window.location.replace(stripeSession.url);
            } finally {
                this.isLoadingStripeSession = false;
            }
        },
        async openStripeCheckoutSession() {
            await this.openStripeSession(
                () => productSubscriptionService.createStripeCheckoutSession(this.intranet.uid),
            );
        },
        async openCustomerPortalSession() {
            await this.openStripeSession(
                () => productSubscriptionService.createCustomerPortalSession(this.intranet.uid),
            );
        },
        async onOpenSessionClick() {
            if (this.hasSubscription) {
                await this.openCustomerPortalSession();
            } else {
                await this.openStripeCheckoutSession();
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.subscription-card {
    border-radius: var(--border-radius-small);
    max-width: 70%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    justify-content: space-between;
}

.grey {
    color: var(--high-contrast);
}

.left-side {
    width: calc(60% - 2.5rem);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.left-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.trial-period-text {
    margin-bottom: 0.5rem;
}

.right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: calc(35% - 4.5rem);

    ::v-deep {
        .button .content {
            margin: auto;
        }
    }
}

.pricing {
    align-items: flex-start;
    .currency {
        font-size: 2rem;
        line-height: 2rem;
        font-weight: bold;
    }
    .number {
        font-size: 4rem;
        line-height: 3rem;
        font-weight: bold;
    }
    .period {
        font-size: 1rem;
    }
}
</style>
