<template>
    <div>
        <h3 class="border-bottom-low-contrast ptop-medium pbottom-xsmall">
            {{ $t("people_management_header") }}
        </h3>
        <div class="people-management flex">
            <router-link :to="{name: 'people'}">
                <div class="management-card mright-medium">
                    <icon
                        name="user"
                        size="60"
                    />
                    <span class="mtop-small">{{ $t("people") }}</span>
                </div>
            </router-link>
            <router-link :to="{name: 'teams'}">
                <div class="management-card">
                    <icon
                        name="users"
                        size="60"
                    />
                    <span class="mtop-small">{{ $tc("team", 2) }}</span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "PeopleManagement",
};
</script>

<style lang="scss" scoped>
.people-management {
    .management-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        // 2+2rem padding + icon size + margin to text + text height
        width: calc(4rem + 60px + 1rem + 1.4rem);
        padding: 2rem;
        background-color: var(--lowest-contrast);
        border-radius: var(--border-radius-small);

        &:hover {
            background-color: var(--low-contrast);
        }
    }
}
</style>
