















































import Vue from "vue";
import Modal from "@web/components/modals/Modal.vue";
import { intranetService } from "@web/services";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { mapState } from "vuex";
import EverestButton from "@web/components/Button.vue";

export default Vue.extend<any, any, any, any>({
    name: "IntranetRemoval",
    components: {
        EverestButton,
        Modal,
    },
    data() {
        return {
            busy: false
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"])
    },
    methods: {
        async remove() {
            this.busy = true;
            await intranetService.removeIntranet(this.intranet.uid);
            await this.$router.push({ path: "/" });
        },
        openModal(name: string) {
            this.$modal.show(name);
        },
        closeModal(name: string) {
            this.$modal.hide(name);
        },
    }
});
