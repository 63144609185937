<template>
    <div>
        <h2>{{ $t("workspace_and_subscription") }}</h2>
        <div>
            {{ $t("workspace_and_subscription_text") }}&nbsp;
            <everest-button
                type="link primary"
                href="mailto:support@linchpin-hey.com"
                open-in-new-tab
            >
                {{ $t("contact_us") }}
            </everest-button>
        </div>
        <h3 class="border-bottom-low-contrast ptop-medium pbottom-xsmall">
            {{ $t("subscription_section_title") }}
        </h3>
        <subscription-card class="mbottom-medium"/>
        <people-management v-if="showPeopleManagement"/>
        <IntranetRemoval />
    </div>
</template>

<script>
import IntranetRemoval from "./IntranetRemoval";
import SubscriptionCard from "@/views/intranet/admin/account/SubscriptionCard";
import EverestButton from "@/components/Button";
import PeopleManagement from "@/views/intranet/admin/account/PeopleManagement";

export default {
    name: "AccountSettings",
    components: {
        PeopleManagement,
        EverestButton,
        SubscriptionCard,
        IntranetRemoval,
    },
    props: {
        showPeopleManagement: { type: Boolean, default: true },
    },
};
</script>

<style lang="scss" scoped>
.link {
    padding: 0 !important;
}
</style>
